<template>
  <section>
    <ShoppingProgress :step.sync="step" />
    <section class="pb-8">
      <div class="container">
        <div class="order-bg mb-3 mb-md-8" :class="{ success: step === 4, fail: step === 3 }">
          <div :class="{ pay: payMethodes }">
            {{ noteStr }}
            <template v-if="Super.paymentNo">
              <br />
              超商繳費資訊<br />
              繳費代碼:{{ Super.paymentNo }}<br />
              繳費期限:{{ Super.expireDate | toTaipei | moment('YYYY-MM-DD HH:mm:ss') }}
            </template>
            <template v-if="Super.bankCode">
              <br />
              ATM繳費資訊<br />
              繳費銀行代碼:{{ Super.bankCode }}<br />
              繳費虛擬帳號:{{ Super.vAccount }}<br />
              繳費期限:{{ Super.expireDate | toTaipei | moment('YYYY-MM-DD HH:mm:ss') }}
            </template>
          </div>
        </div>
        <!-- 下一步按鈕 -->
        <div class="d-flex justify-content-end">
          <router-link
            class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3"
            to="/member/orders"
          >
            查看訂單
          </router-link>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import ShoppingProgress from '@/components/ShoppingProgress.vue';
import { GetEcPayPaymentInfo } from '@/api/ShoppingCartApi';

export default {
  components: {
    ShoppingProgress,
  },
  data() {
    return {
      step: 4,
      noteStr: '完成訂購！',
      Super: {
        // paymentNo: '',
        // expireDate: '',
      },
      payMethodes: false,
    };
  },
  created() {
    const { step } = this.$route.params;
    const { Qcode, t } = this.$route.query;
    if (step === undefined && Qcode === undefined && t === undefined) this.$router.replace('/');
    this.step = step;
    if (step === 3) this.noteStr = '訂購失敗...';
    if (Qcode !== undefined) {
      this.step = 4;
      this.GetEcPayPaymentInfo(Qcode);
    }
    if (t !== undefined) this.step = 4;
  },
  methods: {
    GetEcPayPaymentInfo(Qcode) {
      GetEcPayPaymentInfo(Qcode).then((response) => {
        if (response.data.statusCode === 0) {
          this.Super = response.data.data;
          this.payMethodes = true;
        }
      });
    },
  },
};
</script>

<style></style>
